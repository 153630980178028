<template>
  <div>

    <div v-if="club.stripeAccountReference">
      <h5 class="w-100 mt-2 mb-2">Ce club est associé à un compte stripe.</h5>
      <div class="title-section mt-1">
        {{ $t('admin.clubs.stripe-cb') }}
      </div>
      <b-row>
        <b-col cols="6" class="mt-2 mb-2">
          <label class="field-name-class">
            {{ $t('admin.clubs.fix-part') }}
          </label>
          <d-text-field
            v-model.number="stripeAccount.cardApplicationFeeAmount"
            size="sm"
            class-name="background-light-blue-inputs"
          />
        </b-col>
        <b-col cols="6" class="mt-2 mb-2">
          <label class="field-name-class">
            {{ $t('admin.clubs.variable-part') }}
          </label>
          <d-text-field
            v-model="stripeAccount.cardApplicationFeePercentage"
            size="sm"
            type="number"
            class-name="background-light-blue-inputs"
          />
        </b-col>
      </b-row>
      <div class="title-section mt-1">
        {{ $t('admin.clubs.stripe-sepa') }}
      </div>
      <b-row>
        <b-col cols="6" class="mt-2 mb-2">
          <label class="field-name-class">
            {{ $t('admin.clubs.fix-part') }}
          </label>
          <d-text-field
            v-model.number="stripeAccount.sepaApplicationFeeAmount"
            size="sm"
            class-name="background-light-blue-inputs"
          />
        </b-col>
        <b-col cols="6" class="mt-2 mb-2">
          <label class="field-name-class">
            {{ $t('admin.clubs.variable-part') }}
          </label>
          <d-text-field
            v-model="stripeAccount.sepaApplicationFeePercentage"
            type="number"
            size="sm"
            class-name="background-light-blue-inputs"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex justify-content-center mt-2">
          <div class="d-btn-small btn text-center d-btn-primary" v-on:click="onValidate">{{$t('general.actions.validate')}}</div>

        </b-col>
      </b-row>
    </div>

    <b-row v-if="club.stripeAccountReference === null">
      <b-col cols="4" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('Prénom gérant') }}
        </label>
        <d-text-field
            v-model="stripe.ownerFirstName"
            size="sm"
            class-name="background-light-blue-inputs"
        />
      </b-col>

      <b-col cols="4" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('Nom gérant') }}
        </label>
        <d-text-field
            v-model="stripe.ownerLastName"
            size="sm"
            class-name="background-light-blue-inputs"
        />
      </b-col>

      <b-col cols="4" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('Nom société') }}
        </label>
        <d-text-field
            v-model="stripe.companyName"
            size="sm"
            class-name="background-light-blue-inputs"
        />
      </b-col>
      <b-col cols="4" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('Adresse société') }}
        </label>
        <d-text-field
            v-model="stripe.companyAddress"
            size="sm"
            class-name="background-light-blue-inputs"
        />
      </b-col>

      <b-col cols="4" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('Ville société') }}
        </label>
        <d-text-field
            v-model="stripe.companyCity"
            size="sm"
            class-name="background-light-blue-inputs"
        />
      </b-col>

      <b-col cols="4" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('Région Société') }}
        </label>
        <d-text-field
            v-model="stripe.companyState"
            size="sm"
            class-name="background-light-blue-inputs"
        />
      </b-col>
      <b-col cols="4" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('Code postal société') }}
        </label>
        <d-text-field
            v-model="stripe.companyZipCode"
            size="sm"
            class-name="background-light-blue-inputs"
        />
      </b-col>

      <b-col cols="4" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('Pays société') }}
        </label>
        <d-select-option
            :options="countriesComputed"
            v-model="stripe.companyCountry"
            :class-name="'background-light-blue-inputs'"
        />
      </b-col>
    </b-row>
    <b-row v-if="club.stripeAccountReference === null">
      <b-col cols="3" class="mt-2 mb-2">
        <div class="d-btn-small btn text-center d-btn-primary btn-full-width" v-on:click="sendFormStripe()">{{$t('general.actions.validate')}}</div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import countries from "@data/countries.json";
import {
  postStripeClub,
  createAccountConnect,
  createPersonAccountConnect
} from "@api/services/stripe/account-connect/accountConnect.api";
import StripeAccount from "@/classes/StripeAccount";
import {_get, _put} from "@api/services/httpService";

export default {
  data: () => ({
    countries: countries,
    members: [],
    timer: null,
    showAddForm: false,
    isBusy: true,
    itemsPerPage: 20,
    totalRows: 0,
    currentPage: 1,
    items: [],
    stripe: {},
    stripeAccount: new StripeAccount()
  }),
  props: {
    club: {
      type: Object,
      default: '',
    },
    family: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    countriesComputed: function () {
      const data = [];
      if (Array.isArray(this.countries)) {
        this.countries.forEach(item => {
          data.push({
            text: item.name,
            value: item.code,
          });
        });
      }
      return data;
    },
  },
  methods: {
    onValidate() {
      this.stripeAccount.sepaApplicationFeePercentage = parseFloat(this.stripeAccount.sepaApplicationFeePercentage);
      this.stripeAccount.cardApplicationFeePercentage = parseFloat(this.stripeAccount.cardApplicationFeePercentage);

      _put(this.club.stripeAccount, this.stripeAccount)
        .then((response) => {
          this.$vs.notify({ time: 10000, title: this.$t('general.actions.notification') ,text: this.$t('general.actions.success-update'), color: 'success',position: 'top-right'});
        })
      ;
    },
    sendFormStripe() {
      this.generateStripeData().then(data => {
        postStripeClub(data).then(res => {
          if (res) {
            this.$vs.notify({
              time: 10000,
              title: this.$t('admin.clubs_stripe.notify.success_create.title'),
              text: this.$t('admin.clubs_stripe.notify.success_create.message'),
              color: 'success',position: 'top-right'
            });
            this.$router.push({name: 'clubs'});
          } else {
            this.club.address = this.club.address[0];
          }
        });
      });
    },
    generateStripeData () {

      return new Promise((resolve, reject) => {

        const dataStripe = {
          club: this.club['@id'],
          companyCountry: this.stripe.companyCountry,
          stripePersonTokenId: '',
          stripeAccountTokenId: ''
        };
        const dataPerson = {
          first_name: this.stripe.ownerFirstName,
          last_name: this.stripe.ownerLastName,
          address: {
            line1: this.stripe.companyAddress,
            city: this.stripe.companyCity,
            state: this.stripe.companyState,
            postal_code: this.stripe.companyZipCode,
          },
        };

        const dataAccount = {
          business_type: 'company',
          company: {
            name: this.stripe.companyName,
            address: {
              line1: this.stripe.companyAddress,
              city: this.stripe.companyCity,
              state: this.stripe.companyState,
              postal_code: this.stripe.companyZipCode,
            },
          },
          tos_shown_and_accepted: true,
        };

        let tokenAccount = '';
        let tokenPerson = '';
        createPersonAccountConnect(dataPerson).then(respPerson => {
          if (respPerson.token.id) {
            dataStripe.stripePersonTokenId = respPerson.token.id;
            createAccountConnect(dataAccount).then(respAccount => {
              if (respAccount.token.id) {
                dataStripe.stripeAccountTokenId = respAccount.token.id;
                return resolve(dataStripe);
              } else {
                return reject;
              }
            }).catch(err => {
              console.error('Error createAccountConnect : ', err)
              return reject;
            });
          } else {
            return reject;
          }
        }).catch(err => {
          console.error('Error createPersonAccounConnect : ', err)
          return reject;
        });

      });


    }
  },
  created() {
    if (this.club.stripeAccount) {
      _get(this.club.stripeAccount)
        .then((response) => {
          this.stripeAccount = new StripeAccount(response.data, {serialize: true});
        })
      ;
    }
    this.stripe = {
      club: this.club['@id'],
      ownerFirstName: '',
      ownerLastName: '',
      companyName: '',
      companyAddress: '',
      companyCity: '',
      companyZipCode: '',
      companyState: '',
      companyCountry: ''
    };
  }
}
</script>

<style scoped>
.groups-label {
  font: normal normal 300 17px Avenir;
  letter-spacing: 0px;
  color: #272727;
  opacity: 1;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.title-section {
  font: normal normal bold 18px Avenir;
  letter-spacing: 0;
  color: #4D4F5C;
  opacity: 1;
}
</style>
