import {_post} from "@api/services/httpService";
const URL = "/stripe/subscriptions"
const stripeKey = process.env.VUE_APP_STRIPE_KEY;
const stripe = Stripe(stripeKey);

export const postStripeSubscription = (data) => _post(URL, data);

export const postStripeClub = (data) => _post('/stripe/accounts', data);

export const createAccountConnect = (data) => stripe.createToken('account', data);

export const createPersonAccountConnect = (data) => stripe.createToken('person', {person: data});
