import { hydrate } from "@/utils/form";

export default class StripeAccount {
  id = null;
  cardApplicationFeeAmount = null;
  cardApplicationFeePercentage = null;
  sepaApplicationFeeAmount = null;
  sepaApplicationFeePercentage = null;

  constructor(object, options) {
    if ("undefined" !== typeof options) {
      if(options.serialize) {
        this.serialize(object);
      }
    }
  }

  serialize (object) {
    hydrate(this, object);
  }

}
